import { isNumber } from 'lodash';
import Feature, { FeatureLike } from 'ol/Feature';
import Map from 'ol/Map';
import { LineString, Point, SimpleGeometry } from 'ol/geom';
import { Group as LayerGroup } from 'ol/layer';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style, Circle } from 'ol/style';
// @ts-ignore
import FlowLine from 'ol-ext/style/FlowLine';
import { Subscription, throttleTime } from 'rxjs';
import tinycolor from 'tinycolor2';

import {
  MapLayerRegistryItem,
  PanelData,
  GrafanaTheme2,
  PluginState,
  EventBus,
  DataHoverEvent,
  DataHoverClearEvent,
  DataFrame,
  TIME_SERIES_TIME_FIELD_NAME
} from '@grafana/data';
import { MapLayerOptions, FrameGeometrySourceMode } from '@grafana/schema';
import { FrameVectorSource } from '../../features/geo/utils/frameVectorSource';

import { StyleEditor } from '../../editor/StyleEditor';
import { routeStyle } from '../../style/markers';
import { defaultStyleConfig, StyleConfig } from '../../style/types';
import { getStyleConfigState } from '../../style/utils';
import { getStyleDimension, isSegmentVisible } from '../../utils/utils';
import { getGeometryField, getLocationMatchers } from '../../features/geo/utils/location';

// Configuration options for Circle overlays
export interface RouteConfig {
  style: StyleConfig;
  arrow?: 0 | 1 | -1;
}

const defaultOptions: RouteConfig = {
  style: {
    ...defaultStyleConfig,
    opacity: 1,
    lineWidth: 2,
  },
  arrow: 0,
};

export const ROUTE_LAYER_ID = 'route';

// Used by default when nothing is configured
export const defaultRouteConfig: MapLayerOptions<RouteConfig> = {
  type: ROUTE_LAYER_ID,
  name: '', // will get replaced
  config: defaultOptions,
  location: {
    mode: FrameGeometrySourceMode.Auto,
  },
  tooltip: false,
};

interface DecomposeColor {
  type: string;
  values: any;
  colorSpace?: string;
}

/**
 * Returns a number whose value is limited to the given range.
 * @param value The value to be clamped
 * @param min The lower boundary of the output range
 * @param max The upper boundary of the output range
 * @returns A number in the range [min, max]
 * @beta
 */
function clamp(value: number, min = 0, max = 1) {
  if (process.env.NODE_ENV !== 'production') {
    if (value < min || value > max) {
      console.error(`The value provided ${ value } is out of range [${ min }, ${ max }].`);
    }
  }

  return Math.min(Math.max(min, value), max);
}

/**
 * Converts a color from CSS hex format to CSS rgb format.
 * @param color - Hex color, i.e. #nnn or #nnnnnn
 * @returns A CSS rgb color string
 * @beta
 */
export function hexToRgb(color: string) {
  color = color.slice(1);

  const re = new RegExp(`.{1,${ color.length >= 6 ? 2 : 1 }}`, 'g');
  let colors = color.match(re);

  if (colors && colors[0].length === 1) {
    colors = colors.map((n) => n + n);
  }

  return colors
    ? `rgb${ colors.length === 4 ? 'a' : '' }(${ colors
      .map((n, index) => {
        return index < 3 ? parseInt(n, 16) : Math.round((parseInt(n, 16) / 255) * 1000) / 1000;
      })
      .join(', ') })`
    : '';
}

/**
 * Returns an object with the type and values of a color.
 *
 * Note: Does not support rgb % values.
 * @param color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla()
 * @returns {object} - A MUI color object: {type: string, values: number[]}
 * @beta
 */
export function decomposeColor(color: string | DecomposeColor): DecomposeColor {
  // Idempotent
  if (typeof color !== 'string') {
    return color;
  }

  if (color.charAt(0) === '#') {
    return decomposeColor(hexToRgb(color));
  }

  const marker = color.indexOf('(');
  const type = color.substring(0, marker);

  if (['rgb', 'rgba', 'hsl', 'hsla', 'color'].indexOf(type) === -1) {
    throw new Error(
      `Unsupported '${ color }' color. The following formats are supported: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla(), color()`
    );
  }

  let values: any = color.substring(marker + 1, color.length - 1);
  let colorSpace;

  if (type === 'color') {
    values = values.split(' ');
    colorSpace = values.shift();
    if (values.length === 4 && values[3].charAt(0) === '/') {
      values[3] = values[3].slice(1);
    }
    if (['srgb', 'display-p3', 'a98-rgb', 'prophoto-rgb', 'rec-2020'].indexOf(colorSpace) === -1) {
      throw new Error(
        `Unsupported ${ colorSpace } color space. The following color spaces are supported: srgb, display-p3, a98-rgb, prophoto-rgb, rec-2020.`
      );
    }
  } else {
    values = values.split(',');
  }

  values = values.map((value: string) => parseFloat(value));
  return { type, values, colorSpace };
}

/**
 * Converts a color object with type and values to a string.
 * @param {object} color - Decomposed color
 * @param color.type - One of: 'rgb', 'rgba', 'hsl', 'hsla'
 * @param {array} color.values - [n,n,n] or [n,n,n,n]
 * @returns A CSS color string
 * @beta
 */
export function recomposeColor(color: DecomposeColor) {
  const { type, colorSpace } = color;
  let values = color.values;

  if (type.indexOf('rgb') !== -1) {
    // Only convert the first 3 values to int (i.e. not alpha)
    values = values.map((n: string, i: number) => (i < 3 ? parseInt(n, 10) : n));
  } else if (type.indexOf('hsl') !== -1) {
    values[1] = `${ values[1] }%`;
    values[2] = `${ values[2] }%`;
  }
  if (type.indexOf('color') !== -1) {
    values = `${ colorSpace } ${ values.join(' ') }`;
  } else {
    values = `${ values.join(', ') }`;
  }

  return `${ type }(${ values })`;
}

/**
 * Set the absolute transparency of a color.
 * Any existing alpha values are overwritten.
 * @param color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla(), color()
 * @param value - value to set the alpha channel to in the range 0 - 1
 * @returns A CSS color string. Hex input values are returned as rgb
 * @beta
 */
export function alpha(color: string, value: number) {
  if (color === '') {
    return '#000000';
  }

  value = clamp(value);

  // hex 3, hex 4 (w/alpha), hex 6, hex 8 (w/alpha)
  if (color[0] === '#') {
    if (color.length === 9) {
      color = color.substring(0, 7);
    } else if (color.length <= 5) {
      let c = '#';
      for (let i = 1; i < 4; i++) {
        c += color[i] + color[i];
      }
      color = c;
    }

    return (
      color +
      Math.round(value * 255)
        .toString(16)
        .padStart(2, '0')
    );
  }
  // rgb(, hsl(
  else if (color[3] === '(') {
    // rgb() and hsl() do not require the "a" suffix to accept alpha values in modern browsers:
    // https://developer.mozilla.org/en-US/docs/Web/CSS/color_value/rgb()#accepts_alpha_value
    return color.replace(')', `, ${ value })`);
  }
  // rgba(, hsla(
  else if (color[4] === '(') {
    return color.substring(0, color.lastIndexOf(',')) + `, ${ value })`;
  }

  const parts = decomposeColor(color);

  if (parts.type === 'color') {
    parts.values[3] = `/${ value }`;
  } else {
    parts.values[3] = value;
  }

  return recomposeColor(parts);
}

/**
 * Map layer configuration for circle overlay
 */
export const routeLayer: MapLayerRegistryItem<RouteConfig> = {
  id: ROUTE_LAYER_ID,
  name: 'Route',
  description: 'Render data points as a route',
  isBaseMap: false,
  showLocation: true,
  state: PluginState.beta,

  /**
   * Function that configures transformation and returns a transformer
   * @param options
   */
  create: async (map: Map, options: MapLayerOptions<RouteConfig>, eventBus: EventBus, theme: GrafanaTheme2) => {
    // Assert default values
    const config = {
      ...defaultOptions,
      ...options?.config,
    };

    const style = await getStyleConfigState(config.style);
    const location = await getLocationMatchers(options.location);
    const source = new FrameVectorSource(location);
    const vectorLayer = new VectorLayer({ source });
    const hasArrows = config.arrow === 1 || config.arrow === -1;

    if (!style.fields && !hasArrows) {
      // Set a global style
      const styleBase = routeStyle(style.base);
      if (style.config.size && style.config.size.fixed) {
        // Applies width to base style if specified
        styleBase.getStroke().setWidth(style.config.size.fixed);
      }
      vectorLayer.setStyle(styleBase);
    } else {
      vectorLayer.setStyle((feature: FeatureLike) => {
        const idx = feature.get('rowIndex') as number;
        const dims = style.dims;
        if (!dims || !isNumber(idx)) {
          return routeStyle(style.base);
        }

        const styles = [];
        const geom = feature.getGeometry();
        const opacity = style.config.opacity ?? 1;
        if (geom instanceof SimpleGeometry) {
          const coordinates = geom.getCoordinates();
          if (coordinates) {
            let startIndex = 0; // Index start for segment optimization
            const pixelTolerance = 2; // For segment to be visible, it must be > 2 pixels (due to round ends)
            for (let i = 0; i < coordinates.length - 1; i++) {
              const segmentStartCoords = coordinates[startIndex];
              const segmentEndCoords = coordinates[i + 1];
              const color1 = tinycolor(
                theme.visualization.getColorByName((dims.color && dims.color.get(startIndex)) ?? style.base.color)
              )
                .setAlpha(opacity)
                .toString();
              const color2 = tinycolor(
                theme.visualization.getColorByName((dims.color && dims.color.get(i + 1)) ?? style.base.color)
              )
                .setAlpha(opacity)
                .toString();

              const arrowSize1 = (dims.size && dims.size.get(startIndex)) ?? style.base.size;
              const arrowSize2 = (dims.size && dims.size.get(i + 1)) ?? style.base.size;

              const flowStyle = new FlowLine({
                visible: true,
                lineCap: config.arrow === 0 ? 'round' : 'square',
                color: color1,
                color2: color2,
                width: (dims.size && dims.size.get(startIndex)) ?? style.base.size,
                width2: (dims.size && dims.size.get(i + 1)) ?? style.base.size,
              });
              if (config.arrow) {
                flowStyle.setArrow(config.arrow);
                if (config.arrow > 0) {
                  flowStyle.setArrowColor(color2);
                  flowStyle.setArrowSize((arrowSize2 ?? 0) * 1.5);
                } else {
                  flowStyle.setArrowColor(color1);
                  flowStyle.setArrowSize((arrowSize1 ?? 0) * 1.5);
                }
              }
              // Only render segment if change in pixel coordinates is significant enough
              if (isSegmentVisible(map, pixelTolerance, segmentStartCoords, segmentEndCoords)) {
                const LS = new LineString([segmentStartCoords, segmentEndCoords]);
                flowStyle.setGeometry(LS);
                styles.push(flowStyle);
                startIndex = i + 1; // Because a segment was created, move onto the next one
              }
            }
            // If no segments created, render a single point
            if (styles.length === 0) {
              const P = new Point(coordinates[0]);
              const radius = ((dims.size && dims.size.get(0)) ?? style.base.size ?? 10) / 2;
              const color = tinycolor(
                theme.visualization.getColorByName((dims.color && dims.color.get(0)) ?? style.base.color)
              )
                .setAlpha(opacity)
                .toString();
              const ZoomOutCircle = new Style({
                image: new Circle({
                  radius: radius,
                  fill: new Fill({
                    color: color,
                  }),
                }),
              });
              ZoomOutCircle.setGeometry(P);
              styles.push(ZoomOutCircle);
            }
          }
          return styles;
        }

        const values = { ...style.base };

        if (dims.color) {
          values.color = dims.color.get(idx);
        }
        return routeStyle(values);
      });
    }

    // Crosshair layer
    const crosshairFeature = new Feature({});
    const crosshairRadius = (style.base.lineWidth || 6) + 2;
    const crosshairStyle = new Style({
      image: new Circle({
        radius: crosshairRadius,
        stroke: new Stroke({
          color: alpha(style.base.color, 0.4),
          width: crosshairRadius + 2,
        }),
        fill: new Fill({ color: style.base.color }),
      }),
    });

    const crosshairLayer = new VectorLayer({
      source: new VectorSource({
        features: [crosshairFeature],
      }),
      style: crosshairStyle,
    });

    const layer = new LayerGroup({
      layers: [vectorLayer, crosshairLayer],
    });

    // Crosshair sharing subscriptions
    const subscriptions = new Subscription();

    subscriptions.add(
      eventBus
        .getStream(DataHoverEvent)
        .pipe(throttleTime(8))
        .subscribe({
          next: (event) => {
            const feature = source.getFeatures()[0];
            const frame = feature?.get('frame') as DataFrame;
            const time = event.payload?.point?.time as number;
            if (frame && time) {
              const timeField = frame.fields.find((f) => f.name === TIME_SERIES_TIME_FIELD_NAME);
              if (timeField) {
                const timestamps: number[] = timeField.values;
                const pointIdx = findNearestTimeIndex(timestamps, time);
                if (pointIdx !== null) {
                  const out = getGeometryField(frame, location);
                  if (out.field) {
                    crosshairFeature.setGeometry(out.field.values[pointIdx]);
                    crosshairFeature.setStyle(crosshairStyle);
                  }
                }
              }
            }
          },
        })
    );

    subscriptions.add(
      eventBus.subscribe(DataHoverClearEvent, (event) => {
        crosshairFeature.setStyle(new Style({}));
      })
    );

    return {
      init: () => layer,
      dispose: () => subscriptions.unsubscribe(),
      update: (data: PanelData) => {
        if (!data.series?.length) {
          return; // ignore empty
        }

        for (const frame of data.series) {
          if (style.fields || hasArrows) {
            style.dims = getStyleDimension(frame, style, theme);
          }

          source.updateLineString(frame);
          break; // Only the first frame for now!
        }
      },

      // Route layer options
      registerOptionsUI: (builder) => {
        builder
          .addCustomEditor({
            id: 'config.style',
            path: 'config.style',
            name: 'Style',
            editor: StyleEditor,
            settings: {
              simpleFixedValues: false,
            },
            defaultValue: defaultOptions.style,
          })
          .addRadio({
            path: 'config.arrow',
            name: 'Arrow',
            settings: {
              options: [
                { label: 'None', value: 0 },
                { label: 'Forward', value: 1 },
                { label: 'Reverse', value: -1 },
              ],
            },
            defaultValue: defaultOptions.arrow,
          });
      },
    };
  },

  // fill in the default values
  defaultOptions,
};

function findNearestTimeIndex(timestamps: number[], time: number): number | null {
  if (timestamps.length === 0) {
    return null;
  } else if (timestamps.length === 1) {
    return 0;
  }
  const lastIdx = timestamps.length - 1;
  if (time < timestamps[0]) {
    return 0;
  } else if (time > timestamps[lastIdx]) {
    return lastIdx;
  }

  const probableIdx = Math.abs(Math.round((lastIdx * (time - timestamps[0])) / (timestamps[lastIdx] - timestamps[0])));
  if (time < timestamps[probableIdx]) {
    for (let i = probableIdx; i > 0; i--) {
      if (time > timestamps[i]) {
        return i < lastIdx ? i + 1 : lastIdx;
      }
    }
    return 0;
  } else {
    for (let i = probableIdx; i < lastIdx; i++) {
      if (time < timestamps[i]) {
        return i > 0 ? i - 1 : 0;
      }
    }
    return lastIdx;
  }
}
