import { throttle, isNumber } from 'lodash';
import { MapBrowserEvent } from 'ol';
import { FeatureLike } from 'ol/Feature';
import { toLonLat } from 'ol/proj';
import { RegularShape, Stroke, Style } from 'ol/style';

import { DataFrame, DataHoverClearEvent, GrafanaTheme2 } from '@grafana/data';

import { GeomapPanel } from '../GeomapPanel';
import { GeomapHoverPayload, GeomapLayerHover } from '../event';
import { MapLayerState } from '../types';

import { getMapLayerState } from './layers';
import { getStyleConfigState } from '../style/utils';
import { circleMarker, getFillColor, RegularShapeId, textLabel } from '../style/markers';
import { DEFAULT_SIZE, StyleConfigState } from '../style/types';
import { getStyleDimension } from './utils';

export const setTooltipListeners = (panel: GeomapPanel) => {
  // Tooltip listener
  panel.map?.on('singleclick', panel.pointerClickListener);
  panel.map?.on('pointermove', throttle(panel.pointerMoveListener, 20));
  panel.map?.getViewport().addEventListener('mouseout', (evt: MouseEvent) => {
    panel.props.eventBus.publish(new DataHoverClearEvent());
  });
};

export const pointerClickListener = (evt: MapBrowserEvent<MouseEvent>, panel: GeomapPanel, theme: GrafanaTheme2) => {
  if (pointerMoveListener(evt, panel, theme)) {
    evt.preventDefault();
    evt.stopPropagation();
    panel.mapDiv!.style.cursor = 'auto';
    panel.setState({ ttipOpen: true });
  }
};

export const pointerMoveListener = (evt: MapBrowserEvent<MouseEvent>, panel: GeomapPanel, theme: GrafanaTheme2) => {
  // If measure menu is open, bypass tooltip logic and display measuring mouse events
  if (panel.state.measureMenuActive) {
    return true;
  }

  // Eject out of this function if map is not loaded or valid tooltip is already open
  if (!panel.map || (panel.state.ttipOpen && panel.state?.ttip?.layers?.length)) {
    return false;
  }

  const mouse = evt.originalEvent;
  const pixel = panel.map.getEventPixel(mouse);
  const hover = toLonLat(panel.map.getCoordinateFromPixel(pixel));

  const { hoverPayload } = panel;
  hoverPayload.pageX = mouse.pageX;
  hoverPayload.pageY = mouse.pageY;
  hoverPayload.point = {
    lat: hover[1],
    lon: hover[0],
  };
  hoverPayload.data = undefined;
  hoverPayload.columnIndex = undefined;
  hoverPayload.rowIndex = undefined;
  hoverPayload.layers = undefined;

  const layers: GeomapLayerHover[] = [];
  const layerLookup = new Map<MapLayerState, GeomapLayerHover>();

  let ttip: GeomapHoverPayload = {} as GeomapHoverPayload;
  panel.map.forEachFeatureAtPixel(
    pixel,
    (feature, layer, geo) => {
      const s: MapLayerState = getMapLayerState(layer);
      //match hover layer to layer in layers
      //check if the layer show tooltip is enabled
      //then also pass the list of tooltip fields if exists
      //this is used as the generic hover event
      if (!hoverPayload.data) {
        const props = feature.getProperties();
        const frame: DataFrame = props['frame'];
        if (frame) {
          hoverPayload.data = ttip.data = frame;
          hoverPayload.rowIndex = ttip.rowIndex = props['rowIndex'];
        }

        if (s?.mouseEvents) {
          s.mouseEvents.next(feature);
        }
      }

      if (s) {
        let h = layerLookup.get(s);
        if (h?.features.length === 1) {
          return;
        }
        if (!h) {
          h = { layer: s, features: [] };
          layerLookup.set(s, h);
          layers.push(h);
        }
        h.features.push(feature);
      }
    },
    {
      layerFilter: (l) => {
        const hoverLayerState = getMapLayerState(l);
        return hoverLayerState?.options?.tooltip !== false;
      },
    }
  );
  panel.hoverPayload.layers = layers.length ? layers : undefined;
  panel.props.eventBus.publish(panel.hoverEvent);

  // This check optimizes Geomap panel re-render behavior (without it, Geomap renders on every mouse move event)
  if (panel.state.ttip === undefined || panel.state.ttip?.layers !== hoverPayload.layers || hoverPayload.layers) {
    panel.setState({ ttip: { ...hoverPayload } });
  }

  if (!layers.length) {
    // clear mouse events
    panel.layers.forEach((layer) => {
      layer.mouseEvents.next(undefined);
    });
  }

  const found = Boolean(layers.length);
  panel.mapDiv!.style.cursor = found ? 'pointer' : 'auto';
  setTimeout(() => clearStyleHoverEffect(layers, found, theme));

  return found;
};

let selectedGeomapLayerHover: GeomapLayerHover[] = []
const clearStyleHoverEffect = (layers: GeomapLayerHover[], found: boolean, theme: GrafanaTheme2) => {
  if (selectedGeomapLayerHover.length) {
    selectedGeomapLayerHover.forEach(({ layer, features }) => {
      // @ts-ignore
      features.forEach(f => f.setStyle())
    });
    selectedGeomapLayerHover = [];
  }
  if (found) {
    selectedGeomapLayerHover = [...layers];
    const { layer, features } = layers[0] || { layer: null, features: [] };
    if (layer && features.length) {
      const config = layer.options.config!;
      // @ts-ignore
      getStyleConfigState({ ...config.style, opacity: 1, lineWidth: 2 }, config.shape || 'circle')
        .then(style => {
          const [feature] = features;
          if (style.fields) {
            const frame = feature.get('frame');
            style.dims = getStyleDimension(frame, style, theme)
          }
          // @ts-ignore
          feature.setStyle(getActiveMaker(feature, config.shape, style));
        });
    }
  }
}

const getActiveMaker = (
  feature: FeatureLike,
  shapeId: RegularShapeId,
  style: StyleConfigState
  // @ts-ignore
): Style => {
  const radius = style.base.size ? style.base.size * 2 : DEFAULT_SIZE;
  const rotation = style.base.rotation ?? 0;
  const idx = feature.get('rowIndex') as number;
  const dims = style.dims;
  const color = !dims || !isNumber(idx) ? style.base.color : dims.color!.get(idx);

  if (shapeId === RegularShapeId.circle) {
    return circleMarker({
      ...style.base,
      color,
      size: style.base.size ? style.base.size * 2 : DEFAULT_SIZE,
      strokeColor: '#eeeeee'
    })
  } else if (shapeId === RegularShapeId.square) {
    return new Style({
      image: new RegularShape({
        stroke: new Stroke({ color: '#eeeeee', width: style.base.lineWidth ?? 2 }),
        fill: getFillColor({ ...style.base, color }),
        points: 4,
        radius,
        rotation: (rotation * Math.PI) / 180 + Math.PI / 4,
      }),
      text: textLabel(style.base),
    })
  } else if (shapeId === RegularShapeId.triangle) {
    return new Style({
      image: new RegularShape({
        stroke: new Stroke({ color: '#eeeeee', width: style.base.lineWidth ?? 2 }),
        fill: getFillColor({ ...style.base, color }),
        points: 3,
        radius,
        rotation: (rotation * Math.PI) / 180,
        angle: 0,
      }),
      text: textLabel(style.base),
    })
  } else if (shapeId === RegularShapeId.star) {
    return new Style({
      image: new RegularShape({
        stroke: new Stroke({ color: '#eeeeee', width: style.base.lineWidth ?? 1 }),
        fill: getFillColor({ ...style.base, color }),
        points: 5,
        radius,
        radius2: radius * 0.4,
        angle: 0,
        rotation: (rotation * Math.PI) / 180,
      }),
      text: textLabel(style.base),
    })
  } else if (shapeId === RegularShapeId.cross) {
    return new Style({
      image: new RegularShape({
        stroke: new Stroke({ color: '#eeeeee', width: style.base.lineWidth ?? 1 }),
        points: 4,
        radius,
        radius2: 0,
        angle: 0,
        rotation: (rotation * Math.PI) / 180,
      }),
      text: textLabel(style.base),
    })
  } else if (shapeId === RegularShapeId.x) {
    return new Style({
      image: new RegularShape({
        stroke: new Stroke({ color: '#eeeeee', width: style.base.lineWidth ?? 1 }),
        points: 4,
        radius,
        radius2: 0,
        rotation: (rotation * Math.PI) / 180 + Math.PI / 4,
      }),
      text: textLabel(style.base),
    })
  }
}

