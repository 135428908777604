import { css } from '@emotion/css';
import React, { useCallback, useMemo } from 'react';

import { GrafanaTheme2, StandardEditorProps } from '@grafana/data';
import { InlineField, InlineFieldRow, useStyles2 } from '@grafana/ui';

import { validateScaleOptions, validateScaleConfig } from '../scale';
import { NumberInput } from '../../../core/components/OptionsUI/NumberInput';


export const ScaleVisibleEditor = (props: StandardEditorProps<{ min: number, max: number; }>) => {
  // @ts-ignore
  const { value, context, onChange, item } = props;
  const { settings } = item;
  const styles = useStyles2(getStyles);

  const minMaxStep = useMemo(() => {
    return validateScaleOptions(settings);
  }, [settings]);

  // Validate and update
  const validateAndDoChange = useCallback(
    (v: {  min: number; max: number; }) => {
      // @ts-ignore
      onChange(validateScaleConfig(v, minMaxStep));
    },
    [onChange, minMaxStep]
  );

  const onMinChange = useCallback(
    (min?: number) => {
      if (min !== undefined) {
        validateAndDoChange({
          ...value,
          min: min || 0,
        });
      }
    },
    [validateAndDoChange, value]
  );

  const onMaxChange = useCallback(
    (max?: number) => {
      if (max !== undefined) {
        validateAndDoChange({
          ...value,
          max,
        });
      }
    },
    [validateAndDoChange, value]
  );

  const val = value ?? {};

  return (
    <>
      <div className={styles.range}>
        <InlineFieldRow className={ styles.rangeInputs }>
          <InlineField label="Min" labelWidth={ 8 } grow={ true }>
            <NumberInput value={ val.min } { ...minMaxStep } onChange={ onMinChange }/>
          </InlineField>
          <InlineField label="Max" labelWidth={ 8 } grow={ true }>
            <NumberInput value={ val.max } { ...minMaxStep } onChange={ onMaxChange }/>
          </InlineField>
        </InlineFieldRow>
      </div>
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  range: css`
    padding-top: 8px;
  `,
  rangeInputs: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
  `
});
