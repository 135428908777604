import Map from 'ol/Map';

import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';

import { MapLayerRegistryItem, MapLayerOptions, GrafanaTheme2, EventBus } from '@grafana/data';
import { getTemplateSrv } from '@grafana/runtime';
import { ZoomEditor } from '../../editor/ZoomEditor';

export interface XYZConfig {
  url: string;
  attribution: string;
  xyzStyle: {
    visible?: {
      min: number;
      max: number;
    }
  },
  style: {
    visible?: {
      min: number;
      max: number;
    }
  }
}

const sampleURL = 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer';
export const defaultXYZConfig: XYZConfig = {
  url: sampleURL + '/tile/{z}/{y}/{x}',
  xyzStyle: {
    visible: {
      min: 0,
      max: 30,
    }
  },
  style: {
    visible: {
      min: 0,
      max: 30,
    }
  },
  attribution: `Tiles © <a href="${sampleURL}">ArcGIS</a>`,
};

export const xyzTiles: MapLayerRegistryItem<XYZConfig> = {
  id: 'xyz',
  name: 'XYZ Tile layer',
  description: 'Add map from a generic tile layer',
  isBaseMap: true,

  create: async (map: Map, options: MapLayerOptions<XYZConfig>, eventBus: EventBus, theme: GrafanaTheme2) => ({
    init: () => {
      const cfg = { ...options.config };

      if (!cfg.url) {
        cfg.url = defaultXYZConfig.url;
        cfg.attribution = cfg.attribution ?? defaultXYZConfig.attribution;
      }

      let url = cfg.url;
      if (getTemplateSrv().containsTemplate(url)) {
        const variables = getTemplateSrv().getVariables();
        // @ts-ignore
        url = getTemplateSrv().replace(url, variables);
      }

      return new TileLayer({
        source: new XYZ({
          url: url,
          attributions: cfg.attribution, // singular?
          minZoom: cfg.xyzStyle?.visible?.min,
          maxZoom: cfg.xyzStyle?.visible?.max,
        }),
        minZoom: cfg.style?.visible?.min,
        maxZoom: cfg.style?.visible?.max,
      });
    },
    registerOptionsUI: (builder) => {
      builder
        .addTextInput({
          path: 'config.url',
          name: 'URL template',
          description: 'Must include {x}, {y} or {-y}, and {z} placeholders',
          settings: {
            placeholder: defaultXYZConfig.url,
          },
        })
        .addCustomEditor({
          id: 'config.xyzStyle',
          path: 'config.xyzStyle',
          name: 'Available zoom level range',
          editor: ZoomEditor,
          settings: {},
          defaultValue: defaultXYZConfig.xyzStyle,
        })
        .addCustomEditor({
          id: 'config.style',
          path: 'config.style',
          name: 'Zoom',
          editor: ZoomEditor,
          settings: {},
          defaultValue: defaultXYZConfig.style,
        })
        .addTextInput({
          path: 'config.attribution',
          name: 'Attribution',
          settings: {
            placeholder: defaultXYZConfig.attribution,
          },
        });
    },
  }),
};

export const genericLayers = [xyzTiles];
