import {
  GrafanaTheme2
} from '@grafana/data';
import { transformExtent, fromLonLat } from 'ol/proj';
import { Map as OpenLayersMap } from 'ol';
import { useStyles2 } from '@grafana/ui';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { css } from '@emotion/css';
import { debounce } from 'lodash';
import axios from 'axios';

type SearchOption = {
  id: string,
  label: string,
  subLabel: string,
  center: [number, number],
  bbox: [number, number, number, number]
};

type SearchInputProps = {
  map: OpenLayersMap
}

const useKeyPress = (targetKey: string) => {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    const downHandler = ({ key }: { key: string }) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    };

    const upHandler = ({ key }: { key: string }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };

    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [targetKey]);

  return keyPressed;
};

export const SearchInput = (props: SearchInputProps) => {
  const styles = useStyles2(getStyles);
  const wrapperRef = useRef(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const arrowUpPressed = useKeyPress('ArrowUp');
  const arrowDownPressed = useKeyPress('ArrowDown');

  useEffect(() => {
    if (arrowUpPressed) {
      dispatch({ type: 'arrowUp' });
    }
  }, [arrowUpPressed]);

  useEffect(() => {
    if (arrowDownPressed) {
      dispatch({ type: 'arrowDown' });
    }
  }, [arrowDownPressed]);

  const { map } = props;
  const [options, setOptions] = useState<SearchOption[]>([]);
  const [input, setInput] = useState('');

  const initialState = { selectedIndex: 0 };
  const reducer = (state: { selectedIndex: number; }, action: { type: any; payload?: any; }) => {
    switch (action.type) {
      case 'reset':
        return {
          selectedIndex: 0
        }
      case 'arrowUp':
        if (options.length && optionsRef.current) {
          optionsRef.current!.childNodes[state.selectedIndex !== 0 ? state.selectedIndex - 1 : options.length - 1]
            // @ts-ignore
            .scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
        }
        return {
          selectedIndex: state.selectedIndex !== 0 ? state.selectedIndex - 1 : options.length - 1,
        };
      case 'arrowDown':
        if (options.length && optionsRef.current) {
          optionsRef.current!.childNodes[state.selectedIndex !== options.length - 1 ? state.selectedIndex + 1 : 0]
            // @ts-ignore
            .scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
        }
        return {
          selectedIndex: state.selectedIndex !== options.length - 1 ? state.selectedIndex + 1 : 0,
        };
      case 'select':
        return { selectedIndex: action.payload };
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    function handleClickOutside(event: { target: any; }) {
      // @ts-ignore
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOptions([]);
        dispatch({ type: 'reset' });
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const searchGeocoding = (search: string) => {
    const defaultParameters = {
      types: ['place', 'postcode', 'address', 'country'],
      language: 'en',
      access_token: 'pk.eyJ1IjoibmlraXRhcnVzaW5ldmVyeW5ldCIsImEiOiJjbGJlemJyenQwMWU0M3hsajExaW5vbWI1In0.nIjxIHfSD0oh2lkBjNP6CA'
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const parametersUrl = new URLSearchParams(defaultParameters).toString();
    return axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${ search }.json?${ parametersUrl }`,
      { withCredentials: false }
    ).then(({ data }) => data);
  }

  const onSearchInputChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (!event.target?.value) {
      setOptions([]);
      dispatch({ type: 'reset' });
      return;
    }

    // @ts-ignore
    setInput(event.target?.value);

    // @ts-ignore
    searchGeocoding(event.target?.value)
      .then((res: { features: Array<{ id: string; text: string; place_name: string; center: [number, number]; bbox: [number, number, number, number]; }>; }) => {
        const options = res.features.map(
          ({ id, text, place_name, center, bbox }: {
            id: string,
            text: string,
            place_name: string,
            center: [number, number],
            bbox: [number, number, number, number]
          }) => ({ id, label: text, subLabel: place_name, center, bbox }));
        setOptions(options);
        dispatch({ type: 'reset' });
      })
  };

  const searchGeocodingDebounce = debounce(onSearchInputChange, 300);

  const onSearchOptionClick = (item: SearchOption) => {
    if (!item.bbox) {
      map?.getView().setCenter(fromLonLat(item.center));
      map?.getView().setZoom(10);
    } else {
      // @ts-ignore
      map?.getView().fit(transformExtent(item.bbox, 'EPSG:4326', 'EPSG:3857'));
    }
    setOptions([]);
    dispatch({ type: 'reset' });
  };

  const onFocus = () => {
    if (input) {
      // @ts-ignore
      onSearchInputChange({ target: { value: input }});
    }
  };

  const handleKeyDown = (event: { key: string; }) => {
    if (event.key === 'Enter' && options.length) {
      onSearchOptionClick(options[state.selectedIndex]);
    }
    if (event.key === 'Enter' && !options.length) {
      onFocus();
    }
  }

  return (
    <div
      ref={ wrapperRef }
      className={ styles.searchInputWrap }
    >
      <input
        type="text"
        placeholder="Search"
        className={ styles.searchInput }
        onFocus={onFocus}
        value={input}
        // @ts-ignore
        onInput={ e => setInput(e.target.value) }
        onChange={ ($event) => searchGeocodingDebounce($event) }
        onKeyDown={ handleKeyDown }
      />
      <div className={ styles.searchOptionsWrap } ref={ optionsRef }>
        {
          options.map((item, idx: number) => (
            <div
              key={ `${ idx }/${ item.label }` }
              className={ styles.searchOption }
              role="button"
              aria-pressed={ idx === state.selectedIndex }
              tabIndex={0}
              style={{
                background: idx === state.selectedIndex ? 'rgb(24, 27, 31)' : 'rgb(17, 18, 23)',
              }}
              onClick={() => {
                onSearchOptionClick(item);
                dispatch({ type: 'select', payload: idx });
              }}
            >
              <div>{ item.label }</div>
              <div>{ item.subLabel }</div>
            </div>
          ))
        }
      </div>
    </div>
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  searchInputWrap: css`
    position: absolute;
    z-index: 1;
    right: 0;
    margin: 8px 8px 0 0;
    padding: 0 0 0 8px;
  `,
  searchInput: css`
    padding: 4px 8px;
  `,
  searchOptionsWrap: css`
    position: absolute;
    max-height: 200px;
    overflow: auto;
    background: rgb(17, 18, 23);
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    padding: 4px 0;
  `,
  searchOption: css`
    padding: 4px 0;
    border-bottom: 1px solid rgb(55 61 70);
    &:hover {
      cursor: pointer;
      background: rgb(24, 27, 31);
    }
    & > div {
      padding: 0 8px;
    }
    &:last-child {
      border-bottom: none;
    }
  `,
})
