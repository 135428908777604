import React, { useMemo } from 'react';
import { Observable } from 'rxjs';

import { StandardEditorProps, StandardEditorsRegistryItem, FrameMatcher } from '@grafana/data';
import { Field } from '@grafana/ui';
import { ScaleVisibleEditor } from '../features/dimensions/editors';

import { defaultStyleConfig, StyleConfig } from '../style/types';
import { LayerContentInfo } from '../utils/getFeatures';

export interface StyleEditorOptions {
  layerInfo?: Observable<LayerContentInfo>;
  simpleFixedValues?: boolean;
  displayRotation?: boolean;
  hideSymbol?: boolean;
  frameMatcher?: FrameMatcher;
}

type Props = StandardEditorProps<StyleConfig, StyleEditorOptions>;

export const ZoomEditor = (props: Props) => {
  const { value, onChange, item } = props;
  const context = useMemo(() => {
    if (!item.settings?.frameMatcher) {
      return props.context;
    }

    return { ...props.context, data: props.context.data.filter(item.settings.frameMatcher) };
  }, [props.context, item.settings]);

  const onVisibleChange = (visible: { min: number, max: number }) => {
    onChange({ ...value, visible });
  };

  return (
    <>
      <Field>
        <ScaleVisibleEditor
          value={ value?.visible ?? defaultStyleConfig.visible }
          context={ context }
          // @ts-ignore
          onChange={ onVisibleChange }
          item={
            {
              settings: {
                min: 0,
                max: 30,
              },
            } as StandardEditorsRegistryItem
          }
        />
      </Field>
    </>
  );
};
